import React, { useEffect } from 'react';
import { animated, useSpring } from 'react-spring';

const NotFound = () => {
  const headerAnimation = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: { tension: 200, friction: 10 },
  });

  const bounceAnimation = useSpring({
    from: { transform: 'translateY(0)' },
    to: { transform: 'translateY(-50px)' },
    config: { tension: 200, friction: 10 },
    loop: { reverse: true },
  });

  const wiggleAnimation = useSpring({
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(20deg)' },
    config: { tension: 200, friction: 10 },
    loop: { reverse: true },
  });

  useEffect(() => {
    // Turn off the body's overflow when the component mounts
    document.body.style.overflow = 'hidden';

    // Restore the body's overflow when the component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontSize: 'calc(30vw + 20px)' }}>
        <animated.div style={{ ...headerAnimation, ...bounceAnimation, ...wiggleAnimation }}>
          404
        </animated.div>
      </div>
      <p>Oops! Looks like there's nothing here.</p>
    </div>
  );
};

export default NotFound;
