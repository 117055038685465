import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import ImageBouncer from '../components/ImageBouncer';
import '../components/contact.css';

const contactEmail = 'contact@gabrielgreenough.co.uk';



const EmailOverlay = ({ email, onClose }) => {
    const [isCopied, setIsCopied] = useState(false);
  
    const fadeIn = useSpring({
      from: { opacity: 0, transform: 'scale(0.8)' },
      to: { opacity: 1, transform: 'scale(1)' },
    });
  
    const copyToClipboard = () => {
      navigator.clipboard.writeText(contactEmail);
      setIsCopied(true);
    };
  
    return (
      <animated.div style={fadeIn} className="EmailOverlay">
        <p className="EmailText">{contactEmail}</p>
        <div className="ButtonContainer">
          <button
            className={`CopyButton ${isCopied ? 'Copied' : ''}`}
            onClick={copyToClipboard}
            disabled={isCopied}
          >
            {isCopied ? 'Copied!' : 'Copy'}
          </button>
          <button className="CloseButton" onClick={onClose}>
            Close
          </button>
        </div>
      </animated.div>
    );
  };
  

const Contact = () => {
    useEffect(() => {
        const bodyElement = document.body;
        bodyElement.classList.add("no-scroll");
    
        
      }, []);
    
      useEffect(() => {
        // Apply the body overflow style when the component mounts
        document.body.style.overflow = 'hidden';
    
        
      }, []);
  const images = [
    { imageUrl: './img/ig-colour-small.png', linkUrl: 'https://instagram.com/gabrielgreenough', type: 'instagram' },
    { imageUrl: './img/emailme.png', emailOverlayInfo: contactEmail, type: 'email' },
    { imageUrl: './img/ig-colour-small.png', linkUrl: 'https://instagram.com/gabrielgreenough', type: 'instagram' },
    { imageUrl: './img/emailme.png', emailOverlayInfo: contactEmail, type: 'email' },
    { imageUrl: './img/ig-colour-small.png', linkUrl: 'https://instagram.com/gabrielgreenough', type: 'instagram' },
    { imageUrl: './img/emailme.png', emailOverlayInfo: contactEmail, type: 'email' },
    { imageUrl: './img/ig-colour-small.png', linkUrl: 'https://instagram.com/gabrielgreenough', type: 'instagram' },
    { imageUrl: './img/emailme.png', emailOverlayInfo: contactEmail, type: 'email' },
  ];

  const [showEmailOverlay, setShowEmailOverlay] = useState(false);
  const [emailOverlayInfo, setEmailOverlayInfo] = useState('');

  const handleEmailClick = (linkUrl, type) => {
    if (type === 'email') {
      setEmailOverlayInfo(linkUrl);
      setShowEmailOverlay(true);
    } else {
      window.open(linkUrl, '_blank'); // Open the Instagram link in a new tab
    }
  };

  const handleOverlayClose = () => {
    setShowEmailOverlay(false);
  };

  const obfuscateEmail = (email) => {
    const chars = ['%', '&', '$', '#', '!', '*', '^']; // Array of random characters for obfuscation
    let obfuscatedEmail = '';
    for (let i = 0; i < email.length; i++) {
      const char = email.charAt(i);
      const randomChar = chars[Math.floor(Math.random() * chars.length)];
      obfuscatedEmail += `${randomChar}${char}`;
    }
    return obfuscatedEmail;
  };

  const deobfuscateEmail = (obfuscatedEmail) => {
    if (!obfuscatedEmail) {
      return ''; // Return an empty string if the email is not defined or empty
    }
    let email = '';
    for (let i = 0; i < obfuscatedEmail.length; i += 2) {
      email += obfuscatedEmail.charAt(i + 1);
    }
    return email;
  };

 
  return (
    <div className="Contact">
      {images.map((image, index) => (
        <div key={index} onClick={() => handleEmailClick(image.linkUrl, image.type)}>
          <ImageBouncer
            imageUrl={image.imageUrl}
            linkUrl={image.linkUrl}
            imageCount={images.length}
            index={index}
            className={image.type === 'email' ? 'EmailImage' : 'InstagramImage'} // Add the class based on image type
          />
        </div>
      ))}

      {showEmailOverlay && (
        <div className="OverlayBackground" onClick={handleOverlayClose}>
          <EmailOverlay email={deobfuscateEmail(emailOverlayInfo)} onClose={handleOverlayClose} />
        </div>
      )}
    </div>
  );
};

export default Contact;
