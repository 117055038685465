import React, { useEffect, useRef, useState } from 'react';
import './ImageBouncer.css';

const ImageBouncer = ({ imageUrl, imageCount, index }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [direction, setDirection] = useState({ x: 0, y: 0 });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isFearState, setIsFearState] = useState(false);
  const [imageSpeeds, setImageSpeeds] = useState(Array(imageCount).fill(1));
  const isMobileDevice = window.innerWidth < 520;
  const resetSpeedTimerRef = useRef(null);
  const imageRef = useRef(null);
  const speedDampingFactor = 0.1;
  const positionDampingFactor = 0.1;
  const radius = 200;
  const fearSpeed = 20;
  const resetSpeedDelay = 2000;
  const margin = 10;
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());

  const checkCollision = (x, y, width, height) => {
    const images = Array.from(document.querySelectorAll('.image-bouncer'));

    for (const image of images) {
      if (image === imageRef.current) {
        continue;
      }

      const rect = image.getBoundingClientRect();

      const otherX = rect.left;
      const otherY = rect.top;
      const otherWidth = rect.width;
      const otherHeight = rect.height;

      if (
        x < otherX + otherWidth &&
        x + width > otherX &&
        y < otherY + otherHeight &&
        y + height > otherY
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const handleNormalMovement = () => {
      setPosition((prevPosition) => {
        const { width, height } = imageRef.current.getBoundingClientRect();
        const clientWidth = window.innerWidth;
        const clientHeight = window.innerHeight;
  
        // Increase the speed for mobile devices
        const mobileSpeedMultiplier = 6; // Adjust the speed multiplier as needed
        const speedMultiplier = isMobileDevice ? mobileSpeedMultiplier : 1;
  
        const nextX = prevPosition.x + direction.x * imageSpeeds[index] * speedMultiplier;
        const nextY = prevPosition.y + direction.y * imageSpeeds[index] * speedMultiplier;

        let nextDirectionX = direction.x;
        let nextDirectionY = direction.y;

        if (nextX + width >= clientWidth - margin || nextX <= margin) {
          nextDirectionX = -direction.x;
        }

        if (nextY + height >= clientHeight - margin || nextY <= margin) {
          nextDirectionY = -direction.y;
        }

        const updatedX = Math.max(margin, Math.min(nextX, clientWidth - width - margin));
        const updatedY = Math.max(margin, Math.min(nextY, clientHeight - height - margin));

        if (Math.random() < 0.01) {
          // Generate new random directions
          nextDirectionX = Math.random() > 0.5 ? 1 : -1;
          nextDirectionY = Math.random() > 0.5 ? 1 : -1;
        }

        if (!isFearState) {
          if (resetSpeedTimerRef.current) {
            clearTimeout(resetSpeedTimerRef.current);
          } else if (imageSpeeds[index] !== 1) {
            setImageSpeeds((prevSpeeds) =>
              prevSpeeds.map((speed, i) =>
                i === index ? speed + (1 - speed) * speedDampingFactor : speed
              )
            );
          }
          resetSpeedTimerRef.current = setTimeout(() => {
            resetSpeedTimerRef.current = null;
            if (!mousePositionRef.current.clientX && !mousePositionRef.current.clientY) {
              setIsFearState(false); // Clear fear state if away from the mouse
            }
            setImageSpeeds((prevSpeeds) =>
              prevSpeeds.map((speed, i) => (i === index ? 1 : speed))
            );
          }, resetSpeedDelay);
        } else {
          setImageSpeeds((prevSpeeds) =>
            prevSpeeds.map((speed, i) => (i === index ? 1 : speed))
          ); // Set speed to 1 if in fear state
        }

        setDirection({ x: nextDirectionX, y: nextDirectionY });

        const dampedX = prevPosition.x + (updatedX - prevPosition.x) * positionDampingFactor;
        const dampedY = prevPosition.y + (updatedY - prevPosition.y) * positionDampingFactor;

        return { x: dampedX, y: dampedY };
      });
    };

    const handleFearMovement = () => {
      setPosition((prevPosition) => {
        const { x, y } = prevPosition;
        const { width, height } = imageRef.current.getBoundingClientRect();
        const clientWidth = window.innerWidth;
        const clientHeight = window.innerHeight;

        const targetSpeed = fearSpeed;
        const newSpeed = imageSpeeds[index] + (targetSpeed - imageSpeeds[index]) * speedDampingFactor;
        const maxSpeed = 1.3 * fearSpeed;
        const finalSpeed = newSpeed > maxSpeed ? maxSpeed : newSpeed;

        const mouseX = mousePositionRef.current.clientX;
        const mouseY = mousePositionRef.current.clientY;

        const dx = x + width / 2 - mouseX;
        const dy = y + height / 2 - mouseY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        let nextDirectionX = direction.x;
        let nextDirectionY = direction.y;

        if (distance <= radius && distance > 0) {
          nextDirectionX = dx / distance;
          nextDirectionY = dy / distance;
        } else {
          if (resetSpeedTimerRef.current) {
            clearTimeout(resetSpeedTimerRef.current);
            resetSpeedTimerRef.current = null;
          }
        }

        const nextX = x + nextDirectionX * newSpeed;
        const nextY = y + nextDirectionY * newSpeed;

        const isOutsideX = nextX + width >= clientWidth - margin || nextX <= margin;
        const isOutsideY = nextY + height >= clientHeight - margin || nextY <= margin;

        const updatedX = isOutsideX
          ? Math.max(margin, Math.min(nextX, clientWidth - width - margin))
          : nextX;

        const updatedY = isOutsideY
          ? Math.max(margin, Math.min(nextY, clientHeight - height - margin))
          : nextY;

        setImageSpeeds((prevSpeeds) =>
          prevSpeeds.map((speed, i) => (i === index ? newSpeed : speed))
        );

        if (isOutsideX || isOutsideY) {
          setDirection((prevDirection) => ({
            x: isOutsideX ? -prevDirection.x : prevDirection.x,
            y: isOutsideY ? -prevDirection.y : prevDirection.y,
          }));
        }

        const dampedX = x + (updatedX - x) * positionDampingFactor;
        const dampedY = y + (updatedY - y) * positionDampingFactor;

        return { x: dampedX, y: dampedY };
      });
    };

    if (isInitialLoad) {
      const { width, height } = imageRef.current.getBoundingClientRect();
      const clientWidth = window.innerWidth;
      const clientHeight = window.innerHeight;

      let startX, startY;
      
      if (isMobileDevice) {
        // For mobile devices, randomly place the images around the screen
        startX = Math.random() * (clientWidth - width - 2 * margin) + margin;
        startY = Math.random() * (clientHeight - height - 2 * margin) + margin;
      } else {
        // For non-mobile devices, place the images in a diagonal line
        startX = (((clientWidth * 0.85) - width) / imageCount) * index + (clientWidth * 0.05);
        startY = ((((clientHeight - height) - (clientHeight * 0.5)) / imageCount) * index) + (clientHeight * 0.1);
      }

      setPosition({ x: startX, y: startY });

      setIsInitialLoad(false);
    } else {
      const bounceInterval = setInterval(() => {
        if (isFearState) {
          handleFearMovement();
        } else {
          handleNormalMovement();
        }
      }, 10);

      return () => clearInterval(bounceInterval);
    }
  }, [direction, isInitialLoad, isFearState, imageCount, index, imageSpeeds, isMobileDevice]);

  const handleMouseEnter = () => {
    if (!isFearState) {
      setIsFearState(true);

      if (resetSpeedTimerRef.current) {
        clearTimeout(resetSpeedTimerRef.current);
        resetSpeedTimerRef.current = null;
      }
    }

    setLastInteractionTime(Date.now());
  };

  const handleMouseLeave = () => {
    if (isFearState) {
      resetSpeedTimerRef.current = setTimeout(() => {
        setIsFearState(false);
        resetSpeedTimerRef.current = null;
      }, resetSpeedDelay);
    }
  };

  useEffect(() => {
    const checkElapsedTime = setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastInteractionTime;
      const maxIdleTime = 5000; // 5 seconds

      if (elapsedTime > maxIdleTime && isFearState) {
        setIsFearState(false);
        setImageSpeeds((prevSpeeds) =>
          prevSpeeds.map((speed, i) => (i === index ? speed + (1 - speed) * speedDampingFactor : speed))
        );

        if (resetSpeedTimerRef.current) {
          clearTimeout(resetSpeedTimerRef.current);
          resetSpeedTimerRef.current = null;
        }
      }
    }, 1000);

    return () => clearInterval(checkElapsedTime);
  }, [lastInteractionTime, isFearState, index]);

  const mousePositionRef = useRef({ clientX: 0, clientY: 0 });

  const handleMouseMove = (event) => {
    mousePositionRef.current.clientX = event.clientX;
    mousePositionRef.current.clientY = event.clientY;
  };



  return (
    <a
      
      className={`image-bouncer ${isFearState ? 'fear' : ''}`}
      style={{ left: position.x, top: position.y }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <img src={imageUrl} alt="Bouncing Image" ref={imageRef} />
    </a>
  );
};

export default ImageBouncer;
