import React, { useEffect, useRef, useState } from "react";

const HomePage = () => {
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const playerRef = useRef(null);

  useEffect(() => {
    const bodyElement = document.body;
    bodyElement.classList.add("no-scroll");

    
  }, []);

  useEffect(() => {
    // Apply the body overflow style when the component mounts
    document.body.style.overflow = 'hidden';

    
  }, []);

  const handleVideoLoad = () => {
    setVideoLoaded(true);
    setShowPlaceholder(false); // Reset showPlaceholder to false when video has loaded
  };

  const handleVideoError = () => {
    setVideoLoaded(false);
    setShowPlaceholder(true);
  };

  const videoUrl =
    "https://www.youtube.com/embed/DUvCMHIBKFA?autoplay=1&amp;mute=1&amp;controls=0&amp;enablejsapi=1&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;rel=0&amp;showinfo=0&amp;wmode=opaque&amp;origin=https%3A%2F%2Fgabrieldebruin.co.uk&amp;widgetid=1";
  const [urlValidity, setUrlValidity] = useState(false);

  const checkUrlValidity = () => {
    if (videoUrl.includes("youtube.com")) {
      const startTime = new Date().getTime();

      // Create an image element to check if the URL loads within 3 seconds
      const img = new Image();
      img.src = videoUrl;

      img.onload = function () {
        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;

        if (loadTime <= 1000) {
          setUrlValidity(true);
          setShowPlaceholder(false);
        } else {
          setUrlValidity(false);
          setShowPlaceholder(true);
        }
      };

      img.onerror = function () {
        setUrlValidity(false);
        setShowPlaceholder(true);
      };
    } else {
      setUrlValidity(false);
      setShowPlaceholder(true);
    }
  };

  useEffect(() => {
    checkUrlValidity();
  }, []);

  useEffect(() => {
    if (!urlValidity && !videoLoaded) {
      setShowPlaceholder(true);
    }
  }, [urlValidity, videoLoaded]);

  useEffect(() => {
    const placeholderTimer = setTimeout(() => {
      if (!videoLoaded || !urlValidity) {
        setShowPlaceholder(true);
      }
    }, videoLoaded ? 90000 : 2000);

    return () => {
      clearTimeout(placeholderTimer);
    };
  }, [videoLoaded, urlValidity]);

  useEffect(() => {
    if (showPlaceholder) {
      playerRef.current.classList.add("fade-out"); // Fade out the video
    } else {
      playerRef.current.classList.remove("fade-out"); // Show the video
    }
  }, [showPlaceholder]);

  const [showVideoOverlay, setShowVideoOverlay] = useState(false);

  const handlePlayButtonClick = () => {
    setShowVideoOverlay(true);
  };

  const handleCloseClick = () => {
    setShowVideoOverlay(false);
  };

  return (
    <div>
      <div className="video-container">
        <iframe
          ref={playerRef}
          id="player"
          frameBorder="0"
          allow="autoplay; fullscreen"
          title="Gabriel 2018 Showreel"
          width="640"
          height="360"
          src={videoUrl}
          className={`background-video homevideocontainer ${videoLoaded ? "ready" : ""}`}
          style={{ pointerEvents: "none" }}
          onLoad={handleVideoLoad}
          onError={handleVideoError}
        ></iframe>

        {showPlaceholder && (
          <img
            src="/img/nyc.jpg"
            alt="Placeholder"
            className="placeholder-image fade-in thumbnail-image"
            
          />
        )}
      </div>
      <div>
        <h1 className="homeh1"></h1>
        <div className="play-button-container">
          <img
            src="./img/play2.png"
            alt="Play Button"
            className="play-button"
            onClick={handlePlayButtonClick}
          />
        </div>
      </div>

      {/* Video Overlay */}
      {showVideoOverlay && (
        <div className="video-overlay">
          <div className="video-frame">
            <iframe
              ref={playerRef}
              id="player"
              frameBorder="0"
              allow="autoplay; fullscreen"
              title="Gabriel 2018 Showreel"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/DUvCMHIBKFA?autoplay=1&amp;&amp;controls=1&amp;enablejsapi=1&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;rel=0&amp;showinfo=0&amp;wmode=opaque&amp;origin=https%3A%2F%2Fgabrieldebruin.co.uk&amp;widgetid=1"
              onLoad={handleVideoLoad}
              onError={handleVideoError}
            ></iframe>
          </div>
          <div className="close-button" onClick={handleCloseClick}>
            &times;
          </div>
        </div>
      )}

      {/* ... */}
    </div>
  );
};

export default HomePage;