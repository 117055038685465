import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import workData from '../components/work.json';

const ProjectPage = () => {
  const { projectId } = useParams();
  const project = workData.find((entry) => entry.URL === projectId);
  const navigate = useNavigate(); // Get the useNavigate hook

  useEffect(() => {
    if (!project) {
      // Redirect to 404 page
      navigate('/404');
    } else if (projectId === 'misc') {
      // Redirect to /misc
      navigate('/misc');
    } else {
      // Check the isOld value and update the URL accordingly
      if (project.isOld) {
        const targetURL = `/misc/${projectId}`;
        if (window.location.pathname !== targetURL) {
          navigate(targetURL);
        }
      } else {
        const targetURL = `/work/${projectId}`;
        if (window.location.pathname !== targetURL) {
          navigate(targetURL);
        }
      }
    }
  }, [projectId, project, navigate]);

  // New state to manage the image viewer visibility and current image index
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imageViewerAnimation, setImageViewerAnimation] = useState('');

  // Function to handle opening the image viewer
  const openImageViewer = (index) => {
    setCurrentImageIndex(index);
    setIsImageViewerOpen(true);
    document.body.classList.add('no-scroll');

    // Add the animation class for opening the image viewer
    setImageViewerAnimation('fade-in');

    // Remove the animation class after the opening animation is complete
    setTimeout(() => {
      setImageViewerAnimation('');
    }, 300); // 300ms is the duration of the fade-in animation
  };

  // Function to handle closing the image viewer
  const closeImageViewer = () => {
    // Add the animation class for closing the image viewer
    setImageViewerAnimation('fade-out');

    // Remove the no-scroll class and close the image viewer after the animation ends
    setTimeout(() => {
      setIsImageViewerOpen(false);
      document.body.classList.remove('no-scroll');
    }, 300); // 300ms is the duration of the fade-out animation
  };
  if (!project) {
    // Return null when the project is not found to avoid rendering anything
    return null;
  }

  const addLineBreaksAndLinks = (description) => {
    let formattedDescription = description.replace(/LINEBREAK/g, '<br />');
    const linkRegex = /LINKBEGIN\s*(.*?)\s*LINKEND/g;
    formattedDescription = formattedDescription.replace(
      linkRegex,
      '<a href="http://$1" target="_blank">$1</a>'
    );
    return formattedDescription;
  };

  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  const getEmbedUrls = (videoEmbed) => {
    if (!videoEmbed) {
      return [];
    } else if (Array.isArray(videoEmbed)) {
      return videoEmbed.map((embed) => {
        if (embed.includes('vimeo.com')) {
          const videoId = embed.split('/').pop();
          return `https://player.vimeo.com/video/${videoId}`;
        } else if (embed.includes('youtube.com')) {
          const videoId = embed.split('v=')[1];
          return `https://www.youtube.com/embed/${videoId}`;
        } else {
          return null;
        }
      });
    } else if (videoEmbed.includes('youtube.com')) {
      const videoId = videoEmbed.split('v=')[1];
      return [`https://www.youtube.com/embed/${videoId}`];
    } else if (videoEmbed.includes('vimeo.com')) {
      const videoId = videoEmbed.split('/').pop();
      return [`https://player.vimeo.com/video/${videoId}`];
    } else {
      return [];
    }
  };

  const videoUrls = getEmbedUrls(project.videoEmbed);
  const imageLinks = project.imageLinks || []; // Add this line to handle null imageLinks

  return (
    <div className="project-page">
      <div className="proj-page-title-container">
        <div className="back-button">
          <Link to={window.location.pathname.includes('/misc/') ? '/misc' : '/work'}>
            <i className="fas fa-arrow-left"></i>
          </Link>
        </div>
        <h2 className="proj-heading">{project.projectTitle}</h2>
      </div>
      {videoUrls.map((embedUrl, index) => (
        <div key={index} className="projectvideocontainer">
          <iframe
            className="projectvideocontainer"
            title={`Video Embed ${index + 1}`}
            src={embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ))}
      <p
        className="description"
        dangerouslySetInnerHTML={{
          __html: sanitizeHTML(addLineBreaksAndLinks(project.description)),
        }}
      ></p>
      <div className="additional-images">
        {imageLinks.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Additional Image ${index + 1}`}
            onClick={() => openImageViewer(index)} // Add onClick event to open the image viewer
          />
        ))}
      </div>
      {/* Image viewer */}
      {isImageViewerOpen && (
        <div className={`image-viewer ${imageViewerAnimation}`}>
          <img src={imageLinks[currentImageIndex]} alt="Full-size Image" />
          <div className="close-button" onClick={closeImageViewer}>
            <i className="fas fa-times"></i>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectPage;