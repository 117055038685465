import React, { useState, useEffect } from "react";

const About = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [hue, setHue] = useState(0);

  const handleMouseEnter = () => {
    setIsHovering(true);
    setHue(0); // Reset the hue to 0 on hover to change it quickly
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    // Function to gradually update the hue over time
    const updateHue = () => {
      if (!isHovering) {
        // Gradually change the hue when not hovering
        setHue((prevHue) => (prevHue + 1) % 360);
      } else {
        // Quickly change the hue when hovering
        setHue((prevHue) => (prevHue + 20) % 360);
      }
    };

    const interval = setInterval(updateHue, 50); // Update the hue every 50ms

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [isHovering]);

  return (
    <div className="project-page">
      <h2 className="about-heading">About</h2>
      <div className="content-wrapper">
        <div className={`image-column ${isHovering ? "pfphovered" : ""}`}>
          <img
            src={isHovering ? "./img/pfpsecret.png" : "./img/pfp.jpg"}
            alt="Profile"
            className="profile-image"
          />
        </div>
        <div className="text-column">
          <p>
            director for live action & animation, co-directing with{" "}
            <a href="https://www.katywang.co.uk" target="_blank">
              Katy Wang
            </a>
          </p>
          <p style={{ fontSize: "14px" }}>
            occasionally dop, creative director, writer, producer, editor, compositor,
            lighting designer, 3d printing guy, blender user, colourist, motion designer, electronics hobbyist, chef,
            photographer, programmer,{" "}
            <span
              className="cat-enjoyer"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{
                color: `hsl(${hue}, 100%, ${isHovering ? 40 : 20}%)`, // Adjust the lightness value based on isHovering
              }}
            >
              cat enjoyer
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
