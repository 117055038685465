// NavbarElements.js
import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  height: 85px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem calc((9vw - 10px) / 2);
  z-index: 100;
  transition: background 250ms;

  @media screen and (max-width: 768px) {
    /* On mobile, stack items vertically */
    flex-direction: column;
    height: auto;
  }

  /* Add styles for the homepage nav */
  &.homepage-nav {
    color: lightyellow;
  }
`;

export const NavLink = styled(Link)`
  color: #333333;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #B33A3A;
  }
  &.homepage-nav {
    color: #FDF2E9;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #333333;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    /* On mobile, stack items vertically and center horizontally */
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /* Add styles for the homepage nav */
  &.homepage-nav {
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  @media screen and (max-width: 768px) {
    /* On mobile, center the text */
    text-align: center;
    margin-right: 0px;
    padding-bottom:20px;
  }

  /* Add styles for the homepage nav */
  &.homepage-nav {
    background-color: lightyellow;
  }
`;

export const Logo = styled.h4`
  text-align: center;

  @media screen and (max-width: 768px) {
    /* On mobile, center the text */
    text-align: center;
  }
`;
