import logo from './logo.svg';

function HeaderPg() {
    return (
      <div className="App">
        <header className="App-header">
          123
        </header>
      </div>
    );
  }

  export default HeaderPg;