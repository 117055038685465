import React, { useEffect } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import DOMPurify from 'dompurify';
import WorkProjectPage from '../components/ProjectPage';
import workData from '../components/work.json'; // Import the JSON data

const MiscWork = () => {
  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  useEffect(() => {
    const bodyElement = document.body;
    bodyElement.classList.remove("no-scroll");
  }, []);

  useEffect(() => {
    // Apply the body overflow style when the component mounts
    document.body.style.overflow = 'visible';
  }, []);

  // Filter out projects that are not flagged as "old"
  const filteredWorkData = workData.filter((entry) => entry.isOld);

  return (
    <div className="work-page">
      
      <h4> <Link to="/work">
            <i className="fas fa-arrow-left"></i> {/* Font Awesome arrow icon */}
          </Link>Old & miscellaneous projects</h4>
      
          
        
      
      <div className="work-grid">
        
        {filteredWorkData.map((entry, index) => (
          <Link
            to={`/misc/${entry.URL}`}
            key={index}
            className="project-thumbnail"
          >
            <div className="thumbnail-overlay">
              <h2
                className="thumbnail-title"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(entry.projectTitle) }}
              ></h2>
            </div>
            <img className="thumbnail-image" src={entry.thumbnailImage} alt={entry.projectTitle} />
          </Link>
        ))}
      </div>

      <Routes>
        <Route path="/work/:projectId" element={<WorkProjectPage />} />
      </Routes>
    </div>
  );
};

export default MiscWork;
